.footer {
    border-top: 0.1vw solid #F3F3F3;
    width: 100%;
    font-size: 0.9vw;
    z-index: 100000;
    color: #C4C4C4;
    display: flex;
    justify-content: end;
    text-align: center !important;
    box-sizing: border-box;
    padding : 4vh 4vw;
    margin-top: auto;
    margin-bottom: 0;
}

@media (max-width: 1024px){
    .footer {
        border-top: 0.4vw solid #F3F3F3;
        font-size: 3vw;
        box-sizing: border-box;
        padding : 2vh 4vw;
    }
}