.settingsPage {  
    padding-left: 5vw;
    padding-top: 6vh;
}

.settingPageUserInfoContainer{
    display: flex;
    width: 100%;
}

.settingPageUserInfoContainer > :first-child{
    width: 17%;
}

.settingPageUserInfoContainer > :last-child{
    width: 50%;
}

.coupleAreaSettings{
    display: flex;
    width: 100% !important;
}

.coupleAreaSettings > div {
    width: 100%;
}

.coupleAreaSettings > :last-child {
    margin-left: 2vw;
}

.settingsUserAvatar {
    border: 0.1vw solid lightgray;
    margin: 0;
    padding: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.settingsUserAvatar img {
    width: 100%;
    height: 100%;
    
}

.settingsPageForm {
    margin-left: 3vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.settingsPageForm > * {
    width: 100%;
}

.inputContainerSettings{
    margin-top: 1vh;
    width: 100% !important;
}

@media screen and (max-width: 1024px) {
    .settingsPage{  
        padding-left: 0;
        padding-top: 4vh;
    }

    .settingPageUserInfoContainer {
        flex-direction: column;
        align-items: center;
    }

    .settingPageUserInfoContainer > :first-child{
        width: 25%;
    }
    
    .settingPageUserInfoContainer > :last-child{
        align-self: stretch;
        width: auto;
    }
    
    .SettingsPageHeader {
        text-align: center;
    }

    .settingsPageForm {
        margin-left: 3vw;
        margin-right: 3vw;
    }
}