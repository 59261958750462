body {
    margin : 0;
}

.AdminHeaderContainer {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;

    display: flex;
    flex-direction: column;
    background-color: white;
}

.AdminHeaderContainerInner {
    margin-top: 1vh;
    padding-left: 6vw;

    display: flex;
    flex-direction: row;
    align-items: center;
}

.AdminHeaderContainerInner > :last-child {
    padding-left: 4vw;
    font-size: 2vw;
}

.AdminHeaderLogo {
    width: 2vw;
    height: 2vw;
}

.AdminHeaderHr {
    width: 100%;
    height: 10px;
    border: none;
    margin: 1vh 0 0 0;
    background-color: #92E3DA;
}

.AdminFrameInner {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;
    flex-direction: row;
    align-items: start;
}

.AdminSideMenuContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;

    background-color: white;
}

.AdminFrameBackground {
    position: fixed;
    right: 0;
    bottom: 0;
}

.AdminImgHeader {
    height: 5vh;
}
