.mockHeader,
.commonHeader {
    width: 100%;
    min-height: 8.5vh;
}

.commonHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    z-index: 1000000;
    top: 0;
    background-color: white;
    border-bottom: 1vh solid #92E3DA;
    color: black;
}

.activePointHeader, .pointHeader{
    text-decoration: none;
    color: black;
    width: 10vw;
    display: flex;
    font-size: 1.1vw;
    align-items: center;
    justify-content: center;
    padding: 1.2vh 1vw;
    border: 0.2vw solid white;
}

.activePointHeader {
    background: #92E3DA33;
    border-top: 0.2vw solid #92E3DA;
}

.rightPartHeader{
    align-items: center;
    justify-content: flex-end;
    display: flex;
    margin-right: 3vw;
    word-break: break-all;
}

.pesronImg{
    margin-left: 1vw;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.pesronImg img{
    width: 2.6vw;
}

.leftPartHeader {
    margin-left: 4.3vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.leftPartHeader > * {
    display: flex;
    flex-direction: row;
}

@media (max-width: 1024px){
    .commonHeader {
        min-height: 7vh;
    }

    .activePointHeader, .pointHeader {
        min-width: 30vw;
        font-size: 3vw;
    }

    .pesronImg img {
        width: 10vw;
    }

    .leftPartHeader {
        margin-left: 2vw;
    }

    .leftPartHeader > * {
        flex-direction: column;
    }

    .rightPartHeader {
        font-size: 3vw;
    }
}