.StructuresUIContainer {
    margin-left: 1.5vw;
    margin-bottom: 3vh;
    display: flex;
    flex-direction: column;
}

.StructuresUIHeader,
.StructuresUIPrompt {
    margin-top: 0.5em;
    font-weight: normal;
}

.StructuresUIHeader {
    text-transform: uppercase;
    font-size: 1.8vw;
}

.StructuresUIPrompt {
    font-size: 1.1vw;
}

.StructuresUIContentContainer > * {
    margin: 2vh 2vw;
}

.StructuresUIAddButton {
    cursor: pointer;
    color: #c4c4c4;
}